<template>
  <v-row
  >
    <v-col cols="12">
      <v-card>
        <v-row class="pt-5 mx-2">
          <v-col
            cols="5"
            xs="5"
            class="text-left mt-0 pt-0"
          >
            <v-text-field
              v-model="selectedStartDate"
              dense
              type="date"
              label="Start Date"
            ></v-text-field>
          </v-col>
          <v-col
            cols="5"
            xs="5"
            class="text-right mt-0 pt-0"
          >
            <v-text-field
              v-model="selectedEndDate"
              dense
              type="date"
              label="End Date"
            ></v-text-field>
          </v-col>
          <v-col
            cols="2"
            xs="2"
            class="text-right mt-0 pt-0"
          >
            <spinner v-if="loading" class="text-right"></spinner>
            <v-btn
              v-else
              icon small
              class="primary"
              @click="getRequestedPasswords()"
            >
              <font-awesome-icon icon="fa-solid fa-search"/>
            </v-btn>
          </v-col>
        </v-row>
        <v-card-title class="mt-0 pt-0">
          <span class="me-3">({{  requestedPasswords.length }}) Password Resets</span>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>

        <v-data-table
          :headers="headers"
          :items=" requestedPasswords"
          item-key="id"
          class="table-rounded"
          :items-per-page="10"
          disable-sort
          :search="search"
        >
          <!-- name -->
          <template #[`item.topup_username`]="{item}">
            <router-link :to="'/savings/topup/change_password/'+item.id" class="d-block font-weight-semibold text--primary text-truncate">
              <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.topup_username }}</span>
            </router-link>
          </template>
          <template #[`item.manager_username`]="{item}">
            {{ item.manager_username }}
          </template>
          <!-- status -->
          <template #[`item.validated`]="{item}">
            <v-chip
              small v-if="item.validated"
              color="success"
              @click.stop="setDialog=true"
              @click="getTopUpPassword(item.id)"
              class="font-weight-medium"
            >
              Confirmed
            </v-chip>
            <v-chip
              small v-else
              color="primary"
              class="font-weight-medium"
            >
              Pending
            </v-chip>
          </template>
          <template #[`item.expired`]="{item}">
            <v-chip
              small v-if="item.expired"
              color="secondary"
              class="font-weight-medium"
            >
              Invalid
            </v-chip>
            <v-chip
              small v-else
              color="info"
              class="font-weight-medium"
            >
              Valid
            </v-chip>
          </template>
          <template #[`item.get_date_str`]="{item}">
            {{ item.get_date_str }}
          </template>
        </v-data-table>
      </v-card>
    </v-col>

    <v-dialog width="500px" v-model="setDialog"> 
        <v-card> 
          <v-card-title class="success white--text">Get Changed Password</v-card-title> 
          <v-card-text class="black--text pt-2">
            <p v-html="resetDetails"></p>
            <v-row>
              <v-col
                cols="12"
                sm="8"
                md="6"
              >
                <!-- current password -->
                <v-text-field
                  v-model="topupPassword"
                  :type="isTopupPasswordVisible ? 'text' : 'password'"
                  :append-icon="isTopupPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                  label="TopUp's Password"
                  outlined
                  dense ref="topupPassword"
                  @click:append="isTopupPasswordVisible = !isTopupPasswordVisible"
                ></v-text-field>

              </v-col>
            </v-row>
          </v-card-text> 
          <v-divider></v-divider> 
          <v-card-actions> 
            <v-spacer></v-spacer> 
            <v-btn color="success" text @click="setDialog = false" > Close </v-btn> 
          </v-card-actions> 
        </v-card> 
    </v-dialog> 
  </v-row>
</template>

<script>
import axios from 'axios'
import Spinner from "@/components/Spinner.vue";
import { mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'

export default {
  components: {
    Spinner,
  },

  data() {

    return {
      headers: [
        { text: 'Top Up', value: 'topup_username' },
        { text: 'Changed By', value: 'manager_username' },
        { text: 'Validity', value: 'validated' },
        { text: 'Status', value: 'expired' },
        { text: 'Date/Time', value: 'get_date_str' },
      ],
      requestedPasswords: [],
      search: '',
      selectedStartDate: null,
      selectedEndDate: null,
      loading: false,
      setDialog: false,
      resetDetails: '',
      topupPassword: '',
      isTopupPasswordVisible: false,

      icons: {
        mdiKeyOutline,
        mdiLockOpenOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
    }
  },

  mounted(){
      // executes these after the page has been mounted
      // this.getTopUps();
      document.title = "Manager: Savings | List of Requested Passwords"
  },

  methods:{
    getTopUpPassword(password_request_id){
      axios
        .get('/api/v1/manager/get/password_request/'+password_request_id+'/')
        .then(response => {
          const topup_username = response.data["topup_username"]
          const manager_username = response.data["manager_username"]

          this.SelectedPasswordRequest = password_request_id
          this.topupPassword = response.data["new_password"]
          this.resetDetails = "Get the password for <b>'"+topup_username+"'</b> changed by <b>'"+manager_username+"'</b> below."

        })
        .catch(error => {
          this.setDialog = false
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
    },
    
    async getRequestedPasswords(){
      if (this.selectedStartDate === ''){
        this.selectedStartDate = 'null'
      }
      if (this.selectedEndDate === ''){
        this.selectedEndDate = 'null'
      }
      this.loading = true
      await axios
        .get('/api/v1/manager/reset/topup/password/requests/'+this.selectedStartDate+'/'+this.selectedEndDate+'/')
        .then(response => {
          this. requestedPasswords = response.data  // get the data and fill into campaigns
          console.log(this. requestedPasswords)
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
      this.loading = false
    },

  },
}
</script>
