var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-row',{staticClass:"pt-5 mx-2"},[_c('v-col',{staticClass:"text-left mt-0 pt-0",attrs:{"cols":"5","xs":"5"}},[_c('v-text-field',{attrs:{"dense":"","type":"date","label":"Start Date"},model:{value:(_vm.selectedStartDate),callback:function ($$v) {_vm.selectedStartDate=$$v},expression:"selectedStartDate"}})],1),_c('v-col',{staticClass:"text-right mt-0 pt-0",attrs:{"cols":"5","xs":"5"}},[_c('v-text-field',{attrs:{"dense":"","type":"date","label":"End Date"},model:{value:(_vm.selectedEndDate),callback:function ($$v) {_vm.selectedEndDate=$$v},expression:"selectedEndDate"}})],1),_c('v-col',{staticClass:"text-right mt-0 pt-0",attrs:{"cols":"2","xs":"2"}},[(_vm.loading)?_c('spinner',{staticClass:"text-right"}):_c('v-btn',{staticClass:"primary",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.getRequestedPasswords()}}},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-search"}})],1)],1)],1),_c('v-card-title',{staticClass:"mt-0 pt-0"},[_c('span',{staticClass:"me-3"},[_vm._v("("+_vm._s(_vm.requestedPasswords.length)+") Password Resets")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"table-rounded",attrs:{"headers":_vm.headers,"items":_vm.requestedPasswords,"item-key":"id","items-per-page":10,"disable-sort":"","search":_vm.search},scopedSlots:_vm._u([{key:"item.topup_username",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"d-block font-weight-semibold text--primary text-truncate",attrs:{"to":'/savings/topup/change_password/'+item.id}},[_c('span',{staticClass:"d-block font-weight-semibold text--primary text-truncate"},[_vm._v(_vm._s(item.topup_username))])])]}},{key:"item.manager_username",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.manager_username)+" ")]}},{key:"item.validated",fn:function(ref){
var item = ref.item;
return [(item.validated)?_c('v-chip',{staticClass:"font-weight-medium",attrs:{"small":"","color":"success"},on:{"click":[function($event){$event.stopPropagation();_vm.setDialog=true},function($event){return _vm.getTopUpPassword(item.id)}]}},[_vm._v(" Confirmed ")]):_c('v-chip',{staticClass:"font-weight-medium",attrs:{"small":"","color":"primary"}},[_vm._v(" Pending ")])]}},{key:"item.expired",fn:function(ref){
var item = ref.item;
return [(item.expired)?_c('v-chip',{staticClass:"font-weight-medium",attrs:{"small":"","color":"secondary"}},[_vm._v(" Invalid ")]):_c('v-chip',{staticClass:"font-weight-medium",attrs:{"small":"","color":"info"}},[_vm._v(" Valid ")])]}},{key:"item.get_date_str",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.get_date_str)+" ")]}}],null,true)})],1)],1),_c('v-dialog',{attrs:{"width":"500px"},model:{value:(_vm.setDialog),callback:function ($$v) {_vm.setDialog=$$v},expression:"setDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"success white--text"},[_vm._v("Get Changed Password")]),_c('v-card-text',{staticClass:"black--text pt-2"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.resetDetails)}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"6"}},[_c('v-text-field',{ref:"topupPassword",attrs:{"type":_vm.isTopupPasswordVisible ? 'text' : 'password',"append-icon":_vm.isTopupPasswordVisible ? _vm.icons.mdiEyeOffOutline:_vm.icons.mdiEyeOutline,"label":"TopUp's Password","outlined":"","dense":""},on:{"click:append":function($event){_vm.isTopupPasswordVisible = !_vm.isTopupPasswordVisible}},model:{value:(_vm.topupPassword),callback:function ($$v) {_vm.topupPassword=$$v},expression:"topupPassword"}})],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","text":""},on:{"click":function($event){_vm.setDialog = false}}},[_vm._v(" Close ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }